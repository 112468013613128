/*----------------------------------------*/
/*  13. Footer CSS
/*----------------------------------------*/
.footer-section {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
        
    // Responsive
    &.position-fixed{
        @media #{$tablet-device}{
            position: static !important;
        }
        @media #{$large-mobile}{
            position: static !important;
        }
    }
    & .container-fluid {
        padding: 0 170px;

        // Responsive
        @media #{$laptop-device}{
            padding: 0 50px;
        }
        @media #{$desktop-device}{
            padding: 0 40px;
        }
        @media #{$tablet-device}{
            padding: 0 40px;
        }
        @media #{$large-mobile}{
            padding: 0 40px;
        }
        @media #{$small-mobile}{
            padding: 0 25px;
        }
    }
}

/*-- Footer Copyright --*/
.footer-copyright {
    & p {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0;
        line-height: 30px;
        & a {
            color: $body-color;
            &:hover {
                color: $heading-color;
            }
        }

        // Responsive
        @media #{$tablet-device}{
            text-align: center;
        }
        @media #{$large-mobile}{
            text-align: center;
            font-size: 13px;
        }
    }
}

/*-- Footer Search --*/
.footer-search {
    display: flex;
    align-items: end;
    justify-content: end;
   
        // Responsive
        @media #{$tablet-device}{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @media #{$large-mobile}{
            display: flex;
            align-items: center;
            justify-content: center;
        }
}